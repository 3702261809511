import * as Yup from 'yup';
import { useState  , useEffect} from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack,  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useTheme } from '@emotion/react';
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';


// ----------------------------------------------------------------------

export default function AddQuestionForQuizes() {
    const theme= useTheme();
    const animatedComponents = makeAnimated();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState();
    const [focus, setFocused] = useState(false);
    const [progress, setProgress] = useState();
    const [quizIds , setQuizIds] =  useState() ; 
    const [ quizList , setQuizList] =  useState() ; 

    const RegisterSchema = Yup.object().shape({});

    const handleChangeQuizes = (data) => {
        setQuizIds(data);
    
      }
    const changeHandler = async (event) => {
        setFile(event.target.files[0])
    };

    const defaultValues = {
        quizIds : [] , 
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        
        if( file){
            const formData = new FormData();
            formData.append("file" , file)
            formData.append("quizIds" , "");
            formData.append("quizIds" , "");
            formData.append("quizIds" , "");
            for( let quizId of quizIds){
                formData.append("quizIds" , quizId?.value);
            }
            setLoading(true)
            let token = localStorage.getItem("token");
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
                onUploadProgress: data => {
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            };
            try {
                axios.post(`${process.env.REACT_APP_LIVE_URL}/adminSection/uploadQuestionInMultipleQuizes`, formData, config).then((response) => {
                    if (response.data.status) {
                        toast.success(response.data.msg);
                        setLoading(false);
                       reset();
                       window.location.href = "/dashboard/quiz";
                    }else{
                        toast.error(response.data.msg);
                        setLoading(false);
                    }
    
                })
                    .catch(e => {
                      toast.error(e.message);

                    })
                
            } catch (error) {
                  toast.error(error.message);
            }
        }else{
            toast.error('Please Select File')
        }
    };
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchQuizes() {
          const fullResponse = await fetch(
            `${process.env.REACT_APP_LIVE_URL}/adminPanel/getQuizes?isActive=${true}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const responseJson = await fullResponse.json();
          setQuizList(responseJson?.data?.map((item) => { return { ...item , label : `${item?.label} - ${item?.batchId}`}}));
        }
        fetchQuizes();
        setLoading(false);
    
      }, []);
    return (<>
        <Stack spacing={3} px={5} >
            <h2> Add Questions in Quizes </h2>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <RHFTextField 
                          name="file"
                          onChange={changeHandler}
                          id="file"
                          label="File"
                          error={!file}
                          helperText={file ? `Selected file: ${file.name}` : "Only Excel files (.xls, .xlsx) are allowed." }
                          onFocus={() => setFocused(true)}
                          onBlur={() => setFocused(false)}
                          InputProps={{
                              classes: {
                                 input: "CustomTextField"
                              }
                          }}
                          inputProps={{ accept: ".xls, .xlsx" }}
                          type={ "file" }
                    />
                    <Select
                         closeMenuOnSelect={false}
                         components={animatedComponents}
                         value={quizIds}
                        onChange={handleChangeQuizes}
                         menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        isMulti
                         options={quizList}
                    />
                    
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            console.log(file);
                            // setVal(true)
                            // setMsg('Required')
                        }}
                        
                        >
                        Add Questions 
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Stack >
    </>
    );
}
