import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, MenuItem,  } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useTheme } from '@emotion/react';
import { RHFTextField , FormProvider } from '../../../../components/hook-form';


// ----------------------------------------------------------------------

export default function AddFeature() {
    const theme= useTheme();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState();
    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false);
    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);

    const [progress, setProgress] = useState();

    const RegisterSchema = Yup.object().shape({
        feature: Yup.string().oneOf(['lecture' , 'note' , 'dpp' , 'quiz' , 'announcement' , 'doubt' , 'community' , 'planner']).required('Feature required'),
        order: Yup.number().required('Order Required'),
        isActive: Yup.boolean().required('Status required'),
    });

    const changeHandler = async (event) => {
        let size = event.target.files[0].size / ( 1024 );
        if( size > 100){
            setVal(true);
        }else{
            setFile(event.target.files[0])
        }

    };

    const defaultValues = {
        title: '',
        order: 1,
        isActive: false ,
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        if( file){
            const formData = new FormData();
            formData.append("file" , file)
            formData.append("feature" , data.feature);
            formData.append("isActive" , data.isActive);
            formData.append("order" , data.order);
            setLoading(true)
            let token = localStorage.getItem("token");
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
                onUploadProgress: data => {
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            };
            try {
                axios.post(`${process.env.REACT_APP_LIVE_URL}/adminSection/addFeature`, formData, config).then((response) => {
                    if (response.data.status) {
                        toast.success(response.data.msg);
                        setLoading(false);
                        setPdf(false);
                       reset();
                       window.location.href = "/dashboard/batchFeature";
                    }else{
                        toast.error(response.data.msg);
                        setLoading(false);
                    }
    
                })
                    .catch(e => {
                      toast.error(e.message);

                    })
                
            } catch (error) {
                  toast.error(error.message);
            }
        }else{
            toast.error('Please Select Icon')
        }
    };
    return (<>
        <Stack spacing={3} px={5} >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <RHFTextField name="feature" label="Feature" type='checkbox' select>
                            <MenuItem value={'lecture'}>Lecture</MenuItem>
                            <MenuItem value={'note'}>Note</MenuItem>
                            <MenuItem value={'dpp'}>Dpp</MenuItem>
                            <MenuItem value={'quiz'}>Quiz</MenuItem>
                            <MenuItem value={'announcement'}>Announcement</MenuItem>
                            <MenuItem value={'doubt'}>Doubt</MenuItem>
                            <MenuItem value={'community'}>Community</MenuItem>
                            <MenuItem value={'planner'}>Planner</MenuItem>
                    </RHFTextField>
                    <RHFTextField name="order" label="Order" />
                    <RHFTextField name="file"
                          onChange={changeHandler}
                          id="file"
                          label="File"
                          error={!file && val}
                          helperText='Image Only (Maximum size 100KB allowed) '
                          onFocus={() => setFocused(true)}
                          onBlur={() => setFocused(false)}
                          InputProps={{
                              classes: {
                                 input: "CustomTextField"
                              }
                          }}
                          onClick={() => setPdf(true)}
                          inputProps={{ accept: "image/*" }}
                          type={pdf || focus ? "file" : "text"}
                    />
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="isActive" label="Status" type='checkbox' select>
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                            setMsg('Required')}}
                        >
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Stack >
    </>
    );
}
