import { Avatar, Box, Card, Container, Divider, IconButton, Stack, Tooltip, Typography,Button , TextField } from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios';
import MessageIcon from '@mui/icons-material/Message';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CloseIcon from '@mui/icons-material/Close';
import LockResetSharpIcon from '@mui/icons-material/LockResetSharp';
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import FiberPinIcon from '@mui/icons-material/FiberPin';
import TokenIcon from '@mui/icons-material/Token';
import { useTheme } from '@emotion/react';
const avatarStyle = {
    position: 'absolute',
    top: '-2px',
    // left: '35%',
    // right: 0,
    zIndex: 999
}

const StudentCard = ({ role, detail }) => {
    // const { role, detail } = params;
    // console.log(detail);
    let streams = detail?.Stream?.reduce((acc , cur) => 
        cur+ ", "+acc,
        ""
    )
    // console.log(streams);
    const theme = useTheme();
    const[open , setOpen] = useState(false);
    const [password , setPassword] = useState("");
    const [loading , setLoading] = useState(false);
    const handleOpen = (value) =>{
        console.log("Something")
        setOpen(value);
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }
    const handleReset = async() => {
        const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
            }
        axios.post(`${process.env.REACT_APP_LIVE_URL}/authentication/resetpassword`, { NewPassword : password   , ConfirmPassword : password , email_phoneNumber: detail?.mobileNumber ? detail?.mobileNumber : detail?.email}, config).then((response) => {
            //console.log(response.data);
            if (response.data.status == false ) {
                toast.error(response.data.msg);
                setLoading(false);
            }else{
                setLoading(false);
                toast.success("Password Reset Successfully");
                setOpen(false);
            }
            

        })
            .catch(e => {
                //console.log(e);
            })
    }
    const iconStyle = {
        color: theme.palette.primary.mainTheme,
        background: theme.palette.primary.mainThemeLight,
        borderRadius: '50%', padding: '5px'
    }
    const handleCopy = (token) => {
        navigator.clipboard
          .writeText(token)
          .then(() => {
            toast.success("Token copied to clipboard!");
          })
          .catch((err) => {
            toast.error("Failed to copy token. Try again!");
          });
     }
    return (
        <Container sx={{ height: '100%', zIndex: 9, position: 'relative' }} >
            <Box sx={{ height: '8%', display: 'flex', justifyContent: 'center' }}> <Avatar sx={{ width: 124, height: 124, ...avatarStyle }} alt='jjhzbs' src={detail?.profilePhoto} />
            </Box>
            <Card sx={{ height: '94%' }}>
                <Stack>
                    <Box sx={{ height: '100px', width: '100%' }}></Box>
                    <Typography sx={{ textAlign: 'center', fontWeight: '700' }}>
                        {/* {name} */}
                        {detail?.FullName ? detail?.FullName : detail?.StudentName}
                    </Typography>
                    <Typography sx={{ textAlign: 'center', color: 'lightgray' }}>
                        SD Campus
                    </Typography>
                    <Stack spacing={2} mt={2} mb={2} direction="row" justifyContent="center">
                        {/* <Tooltip title="Message" arrow>
                            <IconButton>
                                <MessageIcon fontSize="large" sx={{ ...iconStyle }} />
                            </IconButton>
                        </Tooltip> */}
                        <Tooltip title={detail?.mobileNumber} arrow>
                            <IconButton>
                                <LocalPhoneIcon fontSize="large" sx={{ ...iconStyle }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={detail?.email} arrow>
                            <IconButton>
                                <EmailIcon fontSize="large" sx={{ ...iconStyle }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Reset Password"}  arrow>
                            <IconButton onClick={() => handleOpen(true)}>
                                <LockResetSharpIcon fontSize="large" sx={{ ...iconStyle }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={detail?.masterOtp}  arrow>
                            <IconButton>
                                <FiberPinIcon fontSize="large" sx={{ ...iconStyle }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Token'  arrow>
                            <IconButton>
                                <TokenIcon onClick={() =>  {handleCopy(detail?.RefreshToken) }} fontSize="large" sx={{ ...iconStyle }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Divider />
                    
                    <Stack justifyContent="space-between" sx={{ minHeight: '200px' }}>
                        <Stack p={2}>
                            {/* <Typography fontSize="medium" sx={{ fontWeight: '600' }}>About Us</Typography> */}
                            {role == 'Student' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Address- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{detail?.Address == '' ? 'Address Not Added' : detail?.Address}</span>  </Typography>}
                            {role == 'Student' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Stream- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{detail?.Stream?.length  == 0 ? 'Stream Not Added' : streams}</span>  </Typography>}
                            {role == 'Student' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Enroll Id- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{detail?.enrollId == '' ? 'Enroll Id Not Added' : detail?.enrollId}</span>  </Typography>}
                            {role == 'Student' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>User Id- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{detail?.userId == '' ? 'userId Not Added' : detail?.userId}</span>  </Typography>}
                            {role == 'Student' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Register Date- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{detail?.created_at == '' ? 'Date Not Added' : detail?.created_at}</span>  </Typography>}
                            {role == 'Student' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>User Name- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{detail?.username == '' ? 'username Not Added' : detail?.username}</span>  </Typography>}
                            {role == 'Student' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Utm Campaign- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{detail?.utm_campaign == '' ? 'Not Exist' : detail?.utm_campaign}</span>  </Typography>}
                            {role == 'Student' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Utm Medium- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{detail?.utm_medium == '' ? 'Not Exist' : detail?.utm_medium}</span>  </Typography>}
                            {role == 'Student' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Utm Source- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{detail?.utm_source == '' ? 'Not Exist' : detail?.utm_source}</span>  </Typography>}
                            {role == 'Student' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Device Name- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{detail?.deviceName == '' ? 'Not Exist' : detail?.deviceName}</span>  </Typography>}
                            {role == 'Student' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Device Config- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{detail?.deviceConfig == '' ? 'Not Exist' : detail?.deviceConfig}</span>  </Typography>}
                            {role == 'Student' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Signin Type- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{detail?.signinType == '' ? 'Signin Type Not Added' : detail?.signinType}</span>  </Typography>}
                            
                            {role == 'Teacher' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Access-
                                {detail?.accessToContent?.map((item, index) => <span key={index} style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{item}</span>)}
                            </Typography>}
                            {role == 'Teacher' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Subject-
                                {detail?.subject?.map((item, index) => <span key={index} style={{ fontWeight: '400', color: 'gray', marginLeft: '10px', textTransform: 'capitalize', wordBreak: 'break-all' }}>{item?.title}</span>)}
                            </Typography>}
                        </Stack>
                        <Divider />
                        <Stack p={2}>
                            <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Profession- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px' }}>{role}</span></Typography>

                            {/* <Typography fontSize="medium" color='gray' sx={{ fontWeight: '400' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography> */}
                        </Stack>
                        {role == 'Teacher' && <Divider />}
                        <Stack p={2} >
                            {role == 'Teacher' && <Typography fontSize="medium" sx={{ fontWeight: '600' }}>Qualification- <span style={{ fontWeight: '400', color: 'gray', marginLeft: '10px' }}>{detail?.qualification}</span></Typography>}
                            {/* {role == 'Teacher' && <Typography fontSize="medium" color='gray' sx={{ fontWeight: '400' }}></Typography>} */}
                        </Stack>
                    </Stack>
                    {/* spacing={3} p={4} m={2} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: 'white', borderRadius: "10px" }} */}
                    { open && <Stack spacing={3} p={4} m={2} justifyContent="space-between" sx={{ display:'flex' ,width:"90%", flexDirection: "column", position:"absolute" , alignItems: "center", justifyContent: "center",zIndex : 399 ,top: "50%", left: "50%",transform: "translate(-50%, -50%)" , backgroundColor: 'white', borderRadius: "10px" }} >
                    <IconButton
                        // sx={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}
                        onClick={() => handleOpen(false)} 
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* <Typography variant='h4' sx={{ alignSelf:"center"}} >Apply Coins</Typography> */}
                    
                        <Stack spacing={3} sx={{ width: '90%', height:"18%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: 'auto' ,}}>
                
                            <TextField name="password" label="Enter New Password" onChange={(e) => handlePassword(e)}/>
                             
                             { loading ? (<CircularProgress color="secondary" />): (<Button variant="contained" color="primary" onClick={handleReset}>
                                Reset Password
                            </Button>) }
                        </Stack>
                    
                </Stack> }
                    

                    {/* <Typography pr={2} color="#F95368" sx={{ textAlign: 'end' }}>View More ></Typography> */}
                </Stack>
            </Card>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            
        </Container>
    )
}

export default StudentCard