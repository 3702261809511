import React from "react";
import * as Yup from "yup";
import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams, useResolvedPath } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Typography,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
} from "../../../components/hook-form";
import Page from "../../../components/Page";
import { useTheme } from "@mui/material/styles";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyCkEditor from "../../../components/hook-form/MyCkEditor";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const UpdateQuiz = () => {
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  // const { setRole } = useContext(User)
  const { quizId } = useParams();
  const [loading, setLoading] = useState();
  const [type, setType] = useState();
  const [id, setId] = useState();
  const [selectValue, setSelectValue] = useState();

  const [file, setFile] = React.useState();
  const theme = useTheme();

  const LoginSchema = Yup.object().shape({});

  const [value, setValue] = React.useState({
    quiz_title: "",
    quiz_duration: "",
    is_active: "",
    is_negative: "",
    quiz_desc: "",
    negativeMarks: "",
    eachQueMarks: "",
    quiz_banner: "",
    language: "",
    link: "",
    linkWith: {},
    valueId: "",
  });

  const onChange2 = (e) => {
    const { name, value } = e.target;
    setValue((prev) => ({ ...prev, [name]: value }));

  };
  // variable default values
  const defaultValues = {
    quiz_title: "",
    quiz_desc: "",
    quiz_duration: "",
    language: "",
    is_active: "",
    is_negative: "",
    negativeMarks: "",
    eachQueMarks: "",
    quiz_banner: "",
    link: "",
    linkWith: '',
  };
  //console.log(value);
  // to get predefines handlers for form and datahandling
  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
  };

  //Helper Function for geting the quiz data
  const [dataForDesc, setDataForDesc] = useState('');
  const addDetails = (TestSeriesDetails) => {
    let arr2 = [];
    TestSeriesDetails?.data?.map((item) => {
      arr2.push(item?._id);
    });
    // setteacher(arr2)
    setValue((prev) => ({
      ...prev,
      quiz_title: TestSeriesDetails?.quiz_title,
      quiz_banner: TestSeriesDetails?.quiz_banner[0],
      quiz_duration: TestSeriesDetails?.quiz_duration,
      quiz_desc: TestSeriesDetails?.quiz_desc,
      is_active: TestSeriesDetails?.is_active,
      is_negative: TestSeriesDetails?.is_negative,
      negativeMarks: TestSeriesDetails?.negativeMarks,
      eachQueMarks: TestSeriesDetails?.eachQueMarks,
      language: TestSeriesDetails?.language,
      linkWith: TestSeriesDetails?.linkWith,
      link: TestSeriesDetails?.link,
      valueId: TestSeriesDetails?.linkWith.id,
      value: TestSeriesDetails?.value,
    }));
    setType(TestSeriesDetails?.link);
    setDataForDesc(TestSeriesDetails?.quiz_desc);
    setId(TestSeriesDetails?.linkWith?.id);
    setSelectValue(TestSeriesDetails?.linkWith)

  };
  const [list, setList] = useState([]);
  useEffect(() => {
    if (type !== '' && type !== 'none') {
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/${type == "batch" ? "getBatches" : "getallTestSeriesdetails"
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson?.data);
        // let data = responseJson.data.filter((item) => item.is_active == true)
        // setList(data);

      }
      fetchDetails();
    }
    setLoading(false);

  }, [type]);

  //console.log(value);

  // to get the quiz data
  const [testSeriesDetails, setTestSeriesDetails] = React.useState();
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getQuizDetails/${quizId}`,
        {
          // const fullResponse = await fetch(`http://localhost:5000/api/v1/adminPanel/getQuizDetails/${quizId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setTestSeriesDetails(responseJson.data);

      addDetails(responseJson.data);
    }

    fetchUsers();
  }, []);
  // //console.log(testSeriesDetails)

  const [val, setVal] = useState(false);

  const handleChangeValue = (data) => {
    setSelectValue(data);
  };

  //Banner
  const [msg, setMsg] = useState("");
  const [focusThree, setFocusedThree] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(false);


  //Adding Details
  const onSubmit = async (data) => {
    setLoading(true);

    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    } else {
      formData.append("file", value?.quiz_banner);
    }

    formData.append("title", value?.quiz_title);
    formData.append("description", dataForDesc);
    formData.append("duration", value?.quiz_duration);
    formData.append("language", value?.language);
    formData.append("isActive", value?.is_active);
    formData.append("isNegative", value?.is_negative);
    if (value?.is_negative) {
      formData.append("negativeMarks", value?.negativeMarks);
    } else {
      formData.append("negativeMarks", "");
    }

    formData.append("eachQueMarks", value?.eachQueMarks);
    // console.log(value?.link);
    formData.append("link", type);

    if (type !== '' && type !== 'none') {
      // formData.append('linkWith', selectValue?._id);
      console.log('At the time of submit , ' , selectValue?.value)
      formData.append('linkWith', selectValue?.value);

    } else {
      formData.append("linkWith", "");
    }

    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(`/adminPanel/editQuiz/${quizId}`, formData, config)
      .then((response) => {
        // axios.put(`http://localhost:5000/api/v1/adminPanel/editQuiz/${quizId}`, formData, config).then((response) => {

        // toast.success("Quiz updated successfully");
        setShowBanner(false);

        if (response.data.msg === "Quiz Updated Successfully") {
          toast.success(response.data.msg);
          setLoading(false);
          setTimeout(() => {
            navigate("/dashboard/quiz");
          }, 1000);
          reset();
        } else {
          toast.error(response.data.msg);
          setLoading(false);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };
  console.log(selectValue);

  return (
    <>
      <Page quiz_title="Test Series">
        <Stack spacing={3} ml={5}>
          <Typography variant="h3">Update Quiz</Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              <RHFTextField
                name="quiz_title"
                value={value.quiz_title}
                onChange={onChange2}
              />

              <Typography>Description</Typography>
              <MyCkEditor dataForDesc={dataForDesc} setDataForDesc={setDataForDesc} />
              <Stack direction="row" spacing={3}>
                <RHFTextField
                  name="quiz_duration"
                  label="quiz_duration"
                  value={value?.quiz_duration}
                  type="number"
                  onChange={onChange2}
                />

                <RHFTextField
                  name="language"
                  label={
                    testSeriesDetails?.language == "enhi"
                      ? "Hindi/English"
                      : testSeriesDetails?.language == "hi"
                        ? "Hindi"
                        : "English"
                  }
                  sx={{ width: "100%" }}
                  select
                  value={value?.language}
                  onChange={onChange2}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="hi">Hindi</MenuItem>
                  <MenuItem value="enhi">Bilungual</MenuItem>
                </RHFTextField>
              </Stack>
              <RHFTextField
                name="file"
                error={!file && val}
                onChange={changeHandler}
                onFocus={() => setFocusedThree(true)}
                onBlur={() => setFocusedThree(false)}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                label="Banner"
                onClick={() => setShowBanner(true)}
                type={showBanner || focusThree ? "file" : "text"}
              />
              <Stack direction="row" spacing={3}>
                <Stack sx={{ width: (type !== 'none' && type !== '') ? "50%" : "100%" }}>
                  <RHFTextField name="link" label={type == 'batch' ? 'Batch' : type == 'testSeries' ? 'Test Series' : "None"} type='checkbox' select >
                    <MenuItem value='none' onClick={() => setType('none')}>None</MenuItem>
                    <MenuItem value='batch' onClick={() => setType('batch')}>Batch</MenuItem>
                    <MenuItem value='testSeries' onClick={() => setType('testSeries')}>Test Series</MenuItem>
                  </RHFTextField>
                </Stack>

                {(type !== 'none' && type !== '') &&
                  //  <RHFTextField name="valueId" label={value?.linkWith?.title ? value?.linkWith?.title : type == "batch" ? "Link With Batch" : "Link With Test Series"} select>
                  // {list?.map((item, index) => (

                  //                 <MenuItem
                  //                   key={index}
                  //                   value={item?._id}
                  //                   onClick={() => setId(item?._id)}
                  //                 >
                  //                   {type == "batch"
                  //                     ? item?.batch_name
                  //                     : item?.testseries_name}
                  //                 </MenuItem>
                  //               )

                  // )}
                  //     </RHFTextField>
                  <Stack sx={{ width: '50%', }}>
                    <Select
                      name="valueId"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      value={selectValue}
                      onChange={handleChangeValue}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      //  defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti={false}
                      options={list}
                    />
                  </Stack>
                }
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {/* <RHFTextField name="no_ques" label='no_ques' value={value?.no_ques} onChange={onChange2} /> */}
                <RHFTextField
                  name="is_negative"
                  label="Allow Negative Marking"
                  sx={{ width: "100%" }}
                  select
                  value={value?.is_negative}
                  onChange={onChange2}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </RHFTextField>
                <RHFTextField
                  name="is_active"
                  label="Status"
                  sx={{ width: "100%" }}
                  select
                  onChange={onChange2}
                  value={value?.is_active}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </RHFTextField>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {value?.is_negative && <RHFTextField
                  name="negativeMarks"
                  label="Negative Marking"
                  value={value?.negativeMarks}
                  onChange={onChange2}
                />}
                <RHFTextField
                  name="eachQueMarks"
                  label="Each Question Marks"
                  value={value?.eachQueMarks}
                  onChange={onChange2}
                />
              </Stack>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                width: "80%",
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
            >
              Update
            </LoadingButton>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <Typography sx={{ color: 'red', textAlign: 'center' }}>{fetchMessage}</Typography> */}
        </Stack>
      </Page>
    </>
  );
};

export default UpdateQuiz;
