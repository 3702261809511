import { Box, Button, Card, Divider, Grid, Input, Popover, Stack, TextField, Typography, Select, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";
// import addicon from '../../../data/add'
//css
import './style.css'
import Page from '../../../components/Page';

//mui icons
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import axios from 'axios';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import LinkIcon from '@mui/icons-material/Link';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import Modal from '@mui/material/Modal';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import FormGroup from '@mui/material/FormGroup';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { ToastContainer, toast } from 'react-toastify';
import ReportIcon from '@mui/icons-material/Report';
import CommentIcon from '@mui/icons-material/Comment';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import CloseIcon from '@mui/icons-material/Close';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import Groups3Icon from '@mui/icons-material/Groups3';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';

import * as XLSX from 'xlsx';
import AddLinkIcon from '@mui/icons-material/AddLink';

import './style.css';
import { useTheme } from '@emotion/react';
import getAccess from '../../../utils/getAccess';
import CreateValidity from './helperComponents/CreateValidity';

import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const styleAssign = {
  position: 'absolute',
  top: '50%',
  left: '47%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CustomToolbar({ rows, columns, ...gridProps }) {
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field != 'actions').map((column) => column.field == 'user' ? "admin" : column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row, index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'student') {
            return row.student.length;
          }
          else if (columnId === 'admin') {
            return `${row.user.FullName}(${row.user.Role})`;
          }
          else if (columnId === 'shareLink') {
            return `${row?.shareLink?.link}`;
          }
          else if (columnId === 'isPaid') {
            return row.isPaid ? 'Paid' : 'Free';
          }
          else if (columnId === 'serialNumber') {
            return index + 1;
          }
          return row[columnId]?.toString()?.replaceAll(",", " ");
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'AllBatches.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: 'red' }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};


const Batch1 = () => {
   const access = getAccess();
  const [batchId, setBatchId] = React.useState("");
  const theme = useTheme()
  const animatedComponents = makeAnimated();
  

  const navigate = useNavigate()

  const [users, usersSet] = React.useState([]);
  const [teacherData, setTeacherData] = React.useState([])
  const [loading, setLoading] = React.useState(true);
  const [batchIdForPopover, setBatchIdForPopover] = React.useState();

  // const role = localStorage.getItem('role')
  const [role , setRole] = React.useState('');
  
  const teacherId = localStorage.getItem('id')
  //Getting Details

  // copy batch 
  const [openCopyBatch, setOpenCopyBatch] = React.useState(false);
  const handleOpenCopyBatch = () => setOpenCopyBatch(true);
  const handleCloseCopyBatch = () => setOpenCopyBatch(false);
  const [copyBatchDetails, setCopybatchDetails] = React.useState({ isCopyLecture: false, isCopyLectResources: false , isLectureActive : false , isLectureResActive : false })
  const handleCopybatchChange = (e, name) => {
    setCopybatchDetails((prev) => ({ ...prev, [name]: e.target.checked }))
  }
  const handleCopybatch = async () => {
    setLoading(true)
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const data = {
      "batchId": batchId,
      "isCopyLecture": copyBatchDetails?.isCopyLecture,
      "isCopyLectResources": copyBatchDetails?.isCopyLectResources,
      "isLectureActive" : copyBatchDetails?.isLectureActive ,
      "isLectureResActive" :  copyBatchDetails?.isLectureResActive , 
    }
    // console.log(data);
    axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/copyBatchDetails`, { ...data }, config).then((response) => {

      setLoading(false)
      // console.log('line282', response)
      if (response.data.msg === 'Batch details copied successfully') {
        toast.success('Batch Copied')


        setTimeout(() => {
          window.location.reload()
        }, 800)
      } else {
        toast.error(response.data.msg)
      }
    })
  }

  const [coins, setCoins] = React.useState(0);
  const [coinError, setCoinError] = React.useState("")
  const [openCoinModal, setOpenCoinModal] = React.useState(false);
  const handleOpenCoinModal = () => setOpenCoinModal(true);
  const handleCloseCoinModal = () => setOpenCoinModal(false);
  const handleSubmitCoinDetails = async (id) => {
    // debugger;
    // console.log(batchIdForPopover);

    const formData = new FormData();

    if (coins > 0) {
      formData.append("isCoinApplicable", "true");
      formData.append("maxAllowedCoins", coins);
      let token = localStorage.getItem("token");

      const config = {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/batchCoins/${batchId}`, formData, config)
        .then((response) => {
          setLoading(false)
          if (response.data.status) {
            handleCloseCoinModal();
            toast.success("Coin is applied.")

            setTimeout(() => {
              window.location.reload()
            }, 800)
          } else {
            toast.error(response.data.msg);
          }
        })

    } else {
      // toast.error("Please Enter Valid Coins");
      setCoinError("Please Enter Valid Coins")
    }

  }

  // batch Order
  const [batchOrder, setBatchOrder] = React.useState(0);
  const [orderError, setOrderError] = React.useState("")
  const [openOrderModal, setOpenOrderModal] = React.useState(false);
  const handleOpenOrderModal = () => setOpenOrderModal(true);
  const handleCloseOrderModal = () => setOpenOrderModal(false);
  const submitOrder = async () => {
    // console.log(batchOrder);\
    // setLoader(true);
    const formData = new FormData();

    // if (orde) {
    formData.append("batchOrder", batchOrder);
    // formData.append("maxAllowedCoins", data?.maxCoin);

    // }
    // setLoading(true)
    let token = localStorage.getItem("token");
    const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/batchOrder/${batchId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ batchOrder })
      });
    const responseJson = await response.json();
    if (responseJson.status) {
      handleCloseOrderModal();
      toast.success(responseJson.msg);

      setTimeout(() => {
        window.location.reload()
      }, 800);
    } else {
      // toast.error(responseJson.msg);
      setOrderError("Please set proper batch Order")
    }
    // setLoader(false);



  }
  const makeActive = async (rowData) => {
    const token = localStorage.getItem("token");
      const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminSection/makeActiveAndInActiveBatch/${rowData?.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
  
      });
      const responseJson = await response.json();
      if( responseJson?.status){
        toast.success(responseJson.msg)
        setTimeout(() => window.location.reload()
        , 1000)
      }else{
        toast.error(responseJson.msg);
      }
  }

  // slug updataion 
  const [slug, setSlug] = React.useState();
  const [slugError, setSlugError] = React.useState("")
  const [openSlugModal, setOpenSlugModal] = React.useState(false);
  const handleOpenSlugModal = () => setOpenSlugModal(true);
  const handleCloseSlugModal = () => setOpenSlugModal(false);
  const submitSlug = async () => {
    let token = localStorage.getItem("token");
    const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updateBatchSlug`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ slug: slug, batchId: batchId })
      });
    const responseJson = await response.json();
    if (responseJson.status) {
      handleCloseSlugModal();
      // setLoading(false);
      toast.success(responseJson.msg);

      setTimeout(() => {
        window.location.reload()
      }, 800);
    } else {

      toast.error(responseJson.msg);
      setSlugError(responseJson.msg)
    }
  }

  // slug link 
  const [shareLink, setShareLink] = React.useState();
  const [shareLinkError, setShareLinkError] = React.useState("")
  const [openShareLinkModal, setOpenShareLinkModal] = React.useState(false);
  const handleOpenShareLinkModal = () => setOpenShareLinkModal(true);
  const handleCloseShareLinkModal = () => setOpenShareLinkModal(false);
  const submitShareLink = async () => {
    let token = localStorage.getItem("token");
    const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updateBatchSlug`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ slug: slug, batchId: batchId })
      });
    const responseJson = await response.json();
    if (responseJson.status) {
      handleCloseShareLinkModal();
      // setLoading(false);
      toast.success(responseJson.msg);

      setTimeout(() => {
        window.location.reload()
      }, 800);
    } else {

      toast.error(responseJson.msg);
      setShareLinkError(responseJson.msg)
    }
  }

  // assign Student
  const [assign, setAssign] = React.useState();
  const [txnId, setTxnId] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [assignError, setAssignError] = React.useState("")
  const [openAssignModal, setOpenAssignModal] = React.useState(false);
  const handleOpenAssignModal = () => setOpenAssignModal(true);
  const handleCloseAssignModal = () => setOpenAssignModal(false);
  const submitAssign = async () => {
    const token = localStorage.getItem("token");
    const authToken = token;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const data = {
      "userIdArr": [selectedStudent],
      "batchId": batchId,
      "transactionId": txnId,
      "amount": amount,
    }

    if (selectedStudent && amount && txnId) {
      axios
        .post(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/batchEnroll`,
          data,
          config
        )
        .then((response) => {
          //console.log(response);
          if (response.data.status) {
            toast.success(response.data.msg, { autoClose: 3000 });
            handleCloseAssignModal();
          } else {
            toast.error(response.data.msg);
            setAssignError(response.data.msg);
          }

        })
        .catch((e) => {
          setAssignError(e?.message);
          toast.error(e, { autoClose: false });
          handleClose();
          //console.log(e);
        });
    } else {
      setAssignError("Please Enter Required!");
    }
  }
  // table things

  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [allStudents, setAllStudents] = React.useState([]);
  const[featureList ,setFeatureList ] =  React.useState([]);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    
    async function fetchUsers() {
      const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatches`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data);
      setRows(responseJson.data);
      setLoading(false)
      const students = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      const studentsJson = await students.json();

      setAllStudents(studentsJson.data);
      setFilteredStudents(studentsJson.data)
      // //console.log(users)
      if (role === 'Teacher') {
        const teacherResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails?teacher_id=${teacherId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        });
        const response = await teacherResponse.json();
        setTeacherData(response.data);
        setLoading(false);
        // setRows(response.data)
      }

      const response2 = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminSection/getAllBatchFeature` , {
        method : 'GET',
        headers : {
          'Content-Type':'application/json',
          Authorization:`Bearer ${token}`

      }
      });
      const responseJson2 = await response2.json();
       setFeatureList(responseJson2.data?.filter((item) => item.isActive != false) ?? []);
    }
    
    fetchUsers();
  }, []);

  const [selectedStudent, setSelectedStudent] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [filteredStudents, setFilteredStudents] = React.useState(allStudents);

  const handleChange = (event) => {
    setSelectedStudent(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const filtered = allStudents.filter(
      (student) =>
        student?.StudentName?.toLowerCase().includes(event?.target?.value?.toLowerCase()) ||
        // student?.email?.toLowerCase().includes(event?.target?.value?.toLowerCase()) ||
        student?.mobileNumber?.includes(event?.target?.value)
    );
    setFilteredStudents(filtered);
  };
  
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
 

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    let confirmDelete = window.confirm("Want to Delete batch?")
    if (confirmDelete) {
      const token = localStorage.getItem('token');
      const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/DeleteBatchesDetails/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson.status) {

        toast.success(resJson.msg);
        setRows(rows.filter((row) => row.id !== id));
      } else {
        toast.error(resJson.msg);
      }
      // toast.success(resJson);
    };
  }

  const handleReport = async ( id ) => {
      toast.success('Please Wait until file downloed ....')
      const token = localStorage.getItem('token');
      const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/lecture/getBatchLecturesReport/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson.status) {
        toast.success(resJson.msg);
        const workbook = XLSX.utils.book_new();

        resJson?.data.forEach((lecture,index) => {
              const lectureName = lecture?.lectureName.substring(0, 21).replace(/[^a-zA-Z ]/g, ""); // Truncate lecture name if necessary
              const report = lecture?.report;
              const worksheet = XLSX.utils.json_to_sheet(report);
              XLSX.utils.book_append_sheet(workbook, worksheet, `${lectureName}${index+1}`);
          });
  
          XLSX.writeFile(workbook, 'BatchLecturesReport.xlsx');
          // setLoading1(false);
        // setRows(rows.filter((row) => row.id !== id));
      } else {
        toast.error(resJson.msg);
      }
      
  }
  
const handleComment = async ( id ) => {
  toast.success('Please Wait until file downloed ....')
  const token = localStorage.getItem('token');
  const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/lecture/getBatchLecturesComments/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
  });
  // setLoadingButton(false)
  const resJson = await res.json();
  if (resJson.status) {
    toast.success(resJson.msg);
    const workbook = XLSX.utils.book_new();

    resJson?.data.forEach((lecture,index) => {
          const lectureName = lecture?.lectureName.substring(0, 21).replace(/[^a-zA-Z ]/g, ""); // Truncate lecture name if necessary
          const comment = lecture?.comment;
          const worksheet = XLSX.utils.json_to_sheet(comment);
          XLSX.utils.book_append_sheet(workbook, worksheet, `${lectureName}${index+1}`);
      });

      XLSX.writeFile(workbook, 'BatchLecturesComments.xlsx');
      // setLoading1(false);
    // setRows(rows.filter((row) => row.id !== id));
  } else {
    toast.error(resJson.msg);
  }
  
}

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    // editDataInDb(newRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const rowsWithSerialNumber = rows?.map((row, index) => ({ ...row, serialNumber: index + 1 }));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModalForValidity, setOpenModalForValidity] = React.useState(false);
  const handleOpenModalForValidity = () => setOpenModalForValidity(true);
  const handleCloseModalForValidity = () => setOpenModalForValidity(false);
 
  const [openModalForLeaderBoard, setOpenModalForLeaderBoard] = React.useState(false);
  const handleOpenModalForLeaderBoard = () => setOpenModalForLeaderBoard(true);
  const handleCloseModalForLeaderBoard = () => setOpenModalForLeaderBoard(false);
  const [ selectedBatch , setSelectedBatch] =  React.useState({});
  const handleChangeBatches = (data) => {
    setSelectedBatch(data);
  }

  const genrateLeaderBoard = async ( id ) => {
    // setLoading(true);
    handleCloseModalForLeaderBoard();
    toast.success('Please Wait until file downloed ....')
    const token = localStorage.getItem('token');
    const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminSection/getBatchQuizLeaderBoard?batchId=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });
    // setLoadingButton(false)
    const resJson = await res.json();
    if (resJson.status) {
      // toast.success(resJson.msg);
      const workbook = XLSX.utils.book_new();
      if( resJson.data.length == 0){
          toast.success('There is no leader board till now')
      }else{
      resJson?.data.forEach((quiz,index) => {
            const quizName = quiz?.title.substring(0, 18).replace(/[^a-zA-Z ]/g, ""); // Truncate lecture name if necessary
            const leaderBoard = quiz?.leaderBoard;
            const worksheet = XLSX.utils.json_to_sheet(leaderBoard);
            XLSX.utils.book_append_sheet(workbook, worksheet, `${quizName}${index+1}`);
        });
  
        XLSX.writeFile(workbook, 'BatchQuizesLeaderBoard.xlsx');
      toast.success(resJson.msg);

      }
    } else {
      toast.error(resJson.msg);
    }
    
  }

  // batc feature update
  const [features, setFeatures] = React.useState([]);
  const [openFeatureModal, setOpenFeatureModal] = React.useState(false);
  const handleOpenFeatureModal = (features) => {
        setOpenFeatureModal(true);
        setFeatures(features)
  };
  const handleFeatureChanges = ( data) => {
    setFeatures(data);
  }
  const handleCloseFeatureModal = () => setOpenFeatureModal(false);
  const submitFeature = async () => {
    let token = localStorage.getItem("token");
    let featureIds=  features?.map((item) => { return item?.value});
    const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminSection/addOrRemoveBatchFeature`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ features: featureIds, batchId: batchId })
      });
    const responseJson = await response.json();
    if (responseJson.status) {
      handleCloseSlugModal();
      // setLoading(false);
      toast.success(responseJson.msg);

      setTimeout(() => {
        window.location.reload()
      }, 800);
    } else {

      toast.error(responseJson.msg);
    }
  }
  

  const columns = [
    {
      field: 'serialNumber',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },

    {
      field: "batch_name",
      headerName: "Title",
      type: "string",
      width: 300,
      align: "left",
      headerAlign: "left",
      editable: true
    },
    {
      field: "batchId",
      headerName: "Batch Id",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
      editable: true
    },
    {
      field: "stream",
      headerName: "Category",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "isPaid",
      headerName: "Paid Status",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? 'green' : 'red' }}>
          {params.value ? 'Paid' : 'Free'}
        </div> )
    },
    {
      field: "charges",
      headerName: "Charges",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "discount",
      headerName: "Discount",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "student",
      headerName: "Students",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,
      renderCell: (params) => (
        <div>{params.value ? params.value.length : 0}</div>
      ),
    },
   

    {
      field: "is_active",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params) => (
        <div style={{ color: params.value == true ? 'green' : 'red' }}>
          {params.value ? 'Active' : 'inActive'}
        </div>
      ),

    },
  
    {
      field: "starting_date",
      headerName: "Start Date",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",
      // editable: true,

    },
    {
      field: "ending_date",
      headerName: "Ending Date",
      type: "string",
      width: 100,
      align: "left",
      headerAlign: "left",

    },
  
    {
      field: "mode",
      headerName: "Mode",
      type: "object",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
       
    {
      field: "user",
      headerName: "Created By",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      // editable: true
      renderCell: (params) => {
        return (
          <div>{`${params.value?.FullName} (${params.value?.Role})`}</div>
        )
      }
    },
    {
      field: "shareLink",
      headerName: "Dynamic Url",
      type: "string",
      width: 150,
      align: 'left',
      headerAlign: "left",
      editable: false,
      renderCell: (params) => (
        params?.value?.link != "" ? <div style={{ cursor : 'pointer'}}onClick={() =>{ navigator.clipboard.writeText(params?.value?.link); toast.success('link Copied')} }>
          <ContentCopyIcon style={{ height: '60%' ,  color: 'blue' }} />
        </div> : <span>NA</span>
      ),
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 700,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows.find(item => item.id === id);
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
          ((access?.accessForTab == 'read' || access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="View Batch" arrow>
            <GridActionsCellItem
              icon={<VisibilityOutlinedIcon />}
              label="View"
              onClick={() => navigate(`/dashboard/batchDetails/${id}`)}
              color="inherit"
            />
          </Tooltip> : <></>)
          ,
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Apply Coins" arrow>
            <GridActionsCellItem
              icon={<MonetizationOnIcon sx={{ color: "gold" }} />}
              label="Edit"
              className="textPrimary"
              // onClick={handleEditClick(id)}
              onClick={() => { setBatchId(id); handleOpenCoinModal() }}
              color="inherit"
            />
          </Tooltip> : <></>),
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Assign Teacher" arrow>
            <GridActionsCellItem
              icon={<AssignmentIndIcon title="Assign Teacher" sx={{ color: "blue" }} />}
              label="Assign Teacher"
              className="textPrimary"
              // onClick={handleEditClick(id)}
              onClick={() => navigate(`/dashboard/batches/addTeacherToBatch/${id}`)}
              color="inherit"
            />
          </Tooltip> : <></>)
          ,
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Change Order" arrow>
            <GridActionsCellItem
              icon={<ChangeCircleIcon sx={{ color: "green" }} />}
              label="Change Order"
              className="textPrimary"
              // onClick={handleEditClick(id)}
              onClick={() => { setBatchId(id); handleOpenOrderModal() }}
              color="inherit"
            />
          </Tooltip> : <></>),
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title='Edit Batch' arrow>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              // onClick={handleEditClick(id)}
              onClick={() => navigate(`/dashboard/batches/updateBatchDetails/${id}`)}
              color="inherit"
            />
          </Tooltip> : <></>)
          ,
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Copy Batch" arrow>
            <GridActionsCellItem
              icon={<FolderCopyIcon />}
              label="Copy Batch"
              className="textPrimary"
              // onClick={handleEditClick(id)}
              onClick={() => { setBatchId(id); handleOpenCopyBatch() }}
              color="inherit"
            />
          </Tooltip> : <></>)
          ,
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Delete">
            <GridActionsCellItem
              icon={<DeleteIcon style={{ color: 'red' }} />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              // onClick={ (id) => handleOpen(id)}
              color="inherit"
            />
          </Tooltip> : <></>),
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Change Status">
            <GridActionsCellItem
              icon={rowData?.is_active ? <ToggleOffIcon style={{ color: 'green' }} /> : <ToggleOnIcon style={{ color: 'red' }} />}
              label="Status"
              onClick={() => { setBatchId(id); makeActive(rowData) }}
              // onClick={() => console.log("line 12"+rowData.is_active)}
              // onClick={ (id) => handleOpen(id)}

              color="inherit"
            />
          </Tooltip> : <></>),
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Slug">
            <GridActionsCellItem
              icon={<LinkIcon style={{ color: 'blue' }} />}
              label="Slug"
              onClick={() => { setBatchId(id); setSlug(rowData.slug); handleOpenSlugModal() }}
              // onClick={() => console.log("line 12"+rowData.is_active)}
              // onClick={ (id) => handleOpen(id)}

              color="inherit"
            />
          </Tooltip> : <></>),
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Assign Faqs">
          <GridActionsCellItem
            icon={<LiveHelpIcon style={{ color: 'red' }} />}
            label="Slug"
            onClick={() => { navigate(`/dashboard/faqsAssign/${'batch'}/${id}`) }}
            // onClick={() => console.log("line 12"+rowData.is_active)}
            // onClick={ (id) => handleOpen(id)}

            color="inherit"
          />
        </Tooltip> : <></>),

          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Batch Lecture Reports">
          <GridActionsCellItem
            icon={<ReportIcon style={{ color: 'red' }} />}
            label="Report"
            onClick={() => handleReport(id)}
            // onClick={() => console.log("line 12"+rowData.is_active)}
            // onClick={ (id) => handleOpen(id)}

            color="inherit"
          />
        </Tooltip> : <></>),
        ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Batch Lecture Comment">
        <GridActionsCellItem
          icon={<CommentIcon style={{ color: 'red' }} />}
          label="Comments"
          onClick={() => handleComment(id)}
          // onClick={() => console.log("line 12"+rowData.is_active)}
          // onClick={ (id) => handleOpen(id)}

          color="inherit"
        />
      </Tooltip> : <></>),
       ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Validity">
       <GridActionsCellItem
         icon={<VerticalSplitIcon style={{ color: 'purple' }} />}
         label="Validity"
         onClick={() => { setBatchId(id) ; handleOpenModalForValidity() }}
         color="inherit"
       />
     </Tooltip> : <></>),
         ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Validity List">
         <GridActionsCellItem
           icon={<FormatListNumberedRtlIcon style={{ color: 'red' }} />}
           label="Validity "
           onClick={() => navigate(`/dashboard/validityOfBatches/${id}`)}
           color="inherit"
         />
       </Tooltip> : <></>),
        ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Quiz's Leader Board">
        <GridActionsCellItem
          icon={<LeaderboardIcon style={{ color: 'blue' }} />}
          label="Genrate Leard board "
          onClick={() => genrateLeaderBoard(id)}
          color="inherit"
        />
      </Tooltip> : <></>) , 
      ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Batch Community">
      <GridActionsCellItem
        icon={<Groups3Icon style={{ color: 'purple' }} />}
        label="Batch Community"
        onClick={() => navigate(`/dashboard/batchCommunity/${id}`)}
        color="inherit"
      />
    </Tooltip> : <></>) ,
    ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Batch Doubts">
    <GridActionsCellItem
      icon={<PsychologyAltIcon style={{ color: 'blue' }} />}
      label="Batch Doubt"
      onClick={() => navigate(`/dashboard/batchDoubt/${id}`)}
      color="inherit"
    />
  </Tooltip> : <></>) ,
    ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Batch Feature">
    <GridActionsCellItem
      icon={<DeveloperModeIcon style={{ color: 'violet' }} />}
      label=""
      onClick={() => { setBatchId(id); handleOpenFeatureModal(rowData.features) }}
      color="inherit"
    />
  </Tooltip> : <></>),
          //   ,
          //   ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ? <Tooltip title="Assign Student">
          //   <GridActionsCellItem
          //     icon={<EventSeatIcon style={{ color: 'green' }} />}
          //     label="Assign Student"
          //     onClick={() => { setBatchId(id); handleOpenAssignModal() }}
          //     color="inherit"
          //   />
          // </Tooltip> : <></>)

        ];
      }
    }
  ]
  //  console.log("rows"+rows);
  return (<>
    {loading ? (

      <Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}> <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} /></Stack>) : (
      <>     <Page title='Batch'>
        {/* <Typography className='page-heading'>All Batches</Typography> */}
        <Stack
          direction="row"
          justifyContent="space-between"
          mb={3}
          pl={3}
          pr={3}
          sx={{ width: "100%" }}
        >
          <Typography className="page-heading">All Batches</Typography>


           <Stack direction="column" mb={3} ml={2} justifyContent="space-evenly" sx={{ width: "80%" }}>
              <Stack direction='row' mb={3} spacing={3}>
              {(access?.access.includes('22') || access?.access.includes('all')) && <Button variant="contained" sx={{
              width: "18%",
              alignSelf: "center",
              background: theme.palette.primary.mainTheme,
              "&:hover": { background: "red" },
            }}
              onClick={() => {

                navigate(
                  `/dashboard/addBatch`
                )
              }
              }

            >Add Batch</Button>}
            {(access?.access.includes('24') || access?.access.includes('all')) && <Button variant="contained" sx={{
              width: "19%",
              alignSelf: "center",
              background: theme.palette.primary.mainTheme,
              "&:hover": { background: "red" },
            }}
              onClick={() => {

                navigate(
                  `/dashboard/batchDetails/addCenterLecture`
                )
              }
              }

            >Add Lecture</Button> }
            {(access?.accessForTab.includes('readWrite') || access?.access.includes('all')) && <Button variant="contained" sx={{
              width: "22%",
              alignSelf: "center",
              background: theme.palette.primary.mainTheme,
              "&:hover": { background: "red" },
            }} onClick={() => {
              navigate(
                `/dashboard/batchDetails/addCenterLectureResources`
              )
            }}>Add Lecture Resource</Button>}
             {(access?.accessForTab.includes('readWrite') || access?.access.includes('all')) && <Button variant="contained" sx={{
              width: "23%",
              alignSelf: "center",
              background: theme.palette.primary.mainTheme,
              "&:hover": { background: "red" },
            }} onClick={() => {
              navigate(
                `/dashboard/addCenterAnnouncement`
              )
            }}>Make Announcement</Button>}
              </Stack>
            <Stack direction='row' spacing={3}>
            
           
            {(access?.accessForTab.includes('readWrite') || access?.access.includes('all')) && <Button variant="contained" sx={{
              width: "22%",
              alignSelf: "center",
              background: theme.palette.primary.mainTheme,
              "&:hover": { background: "red" },
            }} onClick={() => {
              navigate(
                `/dashboard/updateMultipleLectures`
              )
            }}>Edit Multiple Lecture</Button>}
             {(access?.accessForTab.includes('readWrite') || access?.access.includes('all')) && <Button variant="contained" sx={{
              width: "22%",
              alignSelf: "center",
              background: theme.palette.primary.mainTheme,
              "&:hover": { background: "red" },
            }} onClick={() => {
              navigate(
                `/dashboard/addMultipleLectureResources`
              )
            }}>Add Multiple Resource </Button>}
            {(access?.accessForTab.includes('readWrite') || access?.access.includes('all')) && <Button variant="contained" sx={{
              width: "22%",
              alignSelf: "center",
              background: theme.palette.primary.mainTheme,
              "&:hover": { background: "red" },
            }} onClick={() => {
              navigate(
                `/dashboard/addQuizForBatches`
              )
            }}>Add Multiple Quiz </Button>}
            {(access?.accessForTab.includes('readWrite') || access?.access.includes('all')) && <Button variant="contained" sx={{
              width: "22%",
              alignSelf: "center",
              background: theme.palette.primary.mainTheme,
              "&:hover": { background: "red" },
            }} 
            onClick={handleOpenModalForLeaderBoard}
            >Batch Leader Board </Button>}
            </Stack>
          </Stack>
        </Stack>
      


          <DataGrid
            getRowHeight={() => 'auto'}
            // getEstimatedRowHeight={() => 20000}
            rows={rowsWithSerialNumber}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 8,
                },
              },
            }}
            pageSizeOptions={[8, 25, 50]}
            slots={{
              toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel, showQuickFilter: true, }
            }}
          />
          

        <div>
          {/* <Button onClick={handleOpenCopyBatch}>Open modal</Button> */}
          <Modal
            open={openCopyBatch}
            onClose={handleCloseCopyBatch}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <FormGroup >
                <Typography variant='h4'>Copy Details</Typography>
                <FormControlLabel onClick={(e) => handleCopybatchChange(e, 'isCopyLecture')} control={<Checkbox />} label="Lectures" />
                <FormControlLabel onClick={(e) => handleCopybatchChange(e, 'isCopyLectResources')} required control={<Checkbox />} label="Lecture Resource" />
               { copyBatchDetails?.isCopyLecture && <FormControlLabel onClick={(e) => handleCopybatchChange(e, 'isLectureActive')} required control={<Checkbox />} label="Is Lecture Active" /> }
                 { copyBatchDetails?.isCopyLectResources && <FormControlLabel onClick={(e) => handleCopybatchChange(e, 'isLectureResActive')} required control={<Checkbox />} label="Is Lecture Resource Active" /> }

                {/* <FormControlLabel control={<Checkbox />} label="Disabled" /> */}
                <Button variant='outlined' onClick={() => handleCopybatch()}>Done</Button>
              </FormGroup>
            </Box>
          </Modal>
          <Modal
            open={openCoinModal}
            onClose={handleCloseCoinModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <FormGroup >
                <Typography variant='h4' align='center' mb={2}>Apply Coins</Typography>

                {/* <FormControlLabel control={<Checkbox />} label="Disabled" /> */}
                {/* <RHFTextField  name="Coin" label="Max Applied Coins" type="number" onChange={(e) => setCoins(e.target.value)}/>
                                 */}
                <TextField id="outlined-basic" label="Max Applied Coins" type="number" variant="outlined" sx={{ align: 'center' }} onChange={(e) => setCoins(e.target.value)} />
                <FormHelperText id="my-helper-text" sx={{ color: 'red', fontSize: '15px' }}>{coinError}</FormHelperText>
                <Stack direction='row' spacing={2} m={3} align='center' justifyContent="space-evenly">
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => handleCloseCoinModal()}>Cancel</Button>
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => handleSubmitCoinDetails()}>Apply Coins</Button>
                </Stack>

              </FormGroup>
            </Box>
          </Modal>
          <Modal
            open={openOrderModal}
            onClose={handleCloseOrderModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <FormGroup >
                <Typography variant='h4' align='center' mb={2}>Apply Batch Order</Typography>

                {/* <FormControlLabel control={<Checkbox />} label="Disabled" /> */}
                {/* <RHFTextField  name="Coin" label="Max Applied Coins" type="number" onChange={(e) => setCoins(e.target.value)}/>
                                 */}
                <TextField id="outlined-basic2" label="Enter Batch Order" type="number" variant="outlined" sx={{ align: 'center' }} onChange={(e) => setBatchOrder(e.target.value)} />
                <FormHelperText id="my-helper-text" sx={{ color: 'red', fontSize: '15px' }}>{orderError}</FormHelperText>
                <Stack direction='row' spacing={2} m={3} align='center' justifyContent="space-evenly">
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => handleCloseOrderModal()}>Cancel</Button>
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => submitOrder()}>Apply Order</Button>
                </Stack>

              </FormGroup>
            </Box>
          </Modal>
          <Modal
            open={openSlugModal}
            onClose={handleCloseSlugModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <FormGroup >
                <Typography variant='h4' align='center' mb={2}>Provide Batch Slug</Typography>

                {/* <FormControlLabel control={<Checkbox />} label="Disabled" /> */}
                {/* <RHFTextField  name="Coin" label="Max Applied Coins" type="number" onChange={(e) => setCoins(e.target.value)}/>
                                 */}
                <TextField id="outlined-basic2" value={slug} variant="outlined" sx={{ align: 'center' }} onChange={(e) => setSlug(e.target.value)} type='string' inputProps={{ pattern: '[A-Za-z0-9\s\-]*', }} />
                <FormHelperText id="my-helper-text" sx={{ color: 'red', fontSize: '15px' }}>{slugError}</FormHelperText>
                <Stack direction='row' spacing={2} m={3} align='center' justifyContent="space-evenly">
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => handleCloseSlugModal()}>Cancel</Button>
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => submitSlug()}>Update Slug</Button>
                </Stack>

              </FormGroup>
            </Box>
          </Modal>
          {/* slug link */}
          <Modal
            open={openShareLinkModal}
            onClose={handleCloseShareLinkModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <FormGroup >
                <Typography variant='h4' align='center' mb={2}>Provide Batch Share Link</Typography>

                {/* <FormControlLabel control={<Checkbox />} label="Disabled" /> */}
                {/* <RHFTextField  name="Coin" label="Max Applied Coins" type="number" onChange={(e) => setCoins(e.target.value)}/>
                                 */}
                <TextField id="outlined-basic2" value={slug} variant="outlined" sx={{ align: 'center' }} onChange={(e) => setShareLink(e.target.value)} type='string' />
                <FormHelperText id="my-helper-text" sx={{ color: 'red', fontSize: '15px' }}>{slugError}</FormHelperText>
                <Stack direction='row' spacing={2} m={3} align='center' justifyContent="space-evenly">
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => handleCloseShareLinkModal()}>Cancel</Button>
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => submitShareLink()}>Update Slug Link</Button>
                </Stack>

              </FormGroup>
            </Box>
          </Modal>
          <Modal
            open={openAssignModal}
            onClose={handleCloseAssignModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleAssign}>
              <FormGroup>
                <Typography variant='h4' align='center' mb={2}>Assign Student</Typography>
                <Select
                  value={selectedStudent}
                  onChange={handleChange}
                // mb={2}

                >
                  {/* <MenuItem> */}
                  <TextField
                    label="Search for a Student By Number"
                    variant="outlined"
                    onChange={handleSearchChange}
                    value={searchTerm}
                    // mb={2}
                    style={{ padding: "7px" }}
                  />
                  {/* </MenuItem> */}
                  {filteredStudents?.map((student) => (
                    <MenuItem key={student?.id} value={student?.id}>
                      {student?.StudentName + ", " + student?.email + ", " + student?.mobileNumber}
                    </MenuItem>
                  ))}
                </Select>


                <TextField id="outlined-basic2" error={txnId.trim() === ''} helperText={txnId.trim() === '' ? 'Transaction ID Required' : ''} value={txnId} label="Enter Transaction Id" variant="outlined" sx={{ align: 'center' }} onChange={(e) => setTxnId(e.target.value)} type='string' style={{ marginTop: '10px' }} />
                <TextField id="outlined-basic3" value={amount} label="Enter Amount" variant="outlined" sx={{ align: 'center' }} onChange={(e) => setAmount(e.target.value)} type='number' style={{ marginTop: '10px' }} />
                <FormHelperText id="my-helper-text" sx={{ color: 'red', fontSize: '15px' }}>{assignError}</FormHelperText>
                <Stack direction='row' spacing={2} m={3} align='center' justifyContent="space-evenly">
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => handleCloseAssignModal()}>Cancel</Button>
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => submitAssign()}>Assign</Button>
                </Stack>

              </FormGroup>
            </Box>
          </Modal>

          <Modal
            open={openModalForValidity}
            onClose={handleCloseModalForValidity}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleAssign}>
              <FormGroup>
              <Box display="flex" justifyContent="flex-end">
                    <IconButton onClick={()=> handleCloseModalForValidity()}>
                          <CloseIcon />
                    </IconButton>
                </Box>
                <Typography variant='h4' align='center' mb={2}>Create Validity Option</Typography>
                
                <CreateValidity batchId={batchId} handleCloseModalForValidity={handleCloseModalForValidity}/>

              </FormGroup>
            </Box>
          </Modal>
          <Modal
            open={openModalForLeaderBoard}
            onClose={handleCloseModalForLeaderBoard}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
                <Typography variant='h4' align='center' mb={2}>Get Leader board Of Batch</Typography>
                <ReactSelect
                    closeMenuOnSelect={true}
                    placeholder='Please select the batch'
                    components={animatedComponents}
                    value={selectedBatch}
                    onChange={handleChangeBatches}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} 
                    isMulti={false}
                    options={rows}
                />
                <Stack direction='row' spacing={2} m={3} align='center' justifyContent="space-evenly">
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => handleCloseModalForLeaderBoard()}>Cancel</Button>
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => genrateLeaderBoard(selectedBatch?._id)}>Get Leader Board</Button>
                </Stack>
            </Box>
          </Modal>
          <Modal
            open={openFeatureModal}
            onClose={handleCloseFeatureModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
                <Typography variant='h4' align='center' mb={2}>Batch features</Typography>
                <ReactSelect
                    closeMenuOnSelect={true}
                    placeholder='Please select the batch'
                    components={animatedComponents}
                    value={features}
                    onChange={handleFeatureChanges}
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} 
                    isMulti={true}
                    options={featureList}
                />

                
                <Stack direction='row' spacing={2} m={3} align='center' justifyContent="space-evenly">
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => handleCloseFeatureModal()}>Cancel</Button>
                  <Button variant="contained" style={{ backgroundColor: theme.palette.primary.mainTheme, color: 'white', "&:hover": { background: "red" } }} onClick={() => submitFeature()}>Set Feature</Button>
                </Stack>
            </Box>
          </Modal>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

      </Page></>
    )
    }


  </>
  )
}

export default Batch1