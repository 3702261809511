import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
    Stack,
    Typography,
    MenuItem,
    IconButton,
    Grid,
    InputLabel
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import { RHFTextField , FormProvider } from "../../../../components/hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@emotion/react";

const EditFeature = () => {
    const theme = useTheme();
    const { id } = useParams();
    const navigate = useNavigate();
    const [focus, setFocused] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const[val , setVal] = useState("")
    const [restrict, setRestrict] = useState('')
    const [pdf, setPdf] = useState(false)
    const [msg, setMsg] = useState('');
    const[ showImage , setShowImage] = useState(false);
    const [file, setFile] = useState()
    
    const changeHandler = async (event) => {
            setFile(event.target.files[0])
            var fileName = document.getElementById('file').value.toLowerCase();
              if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
             else { setRestrict('JPG Or PDF') }
    };
    
    const handleDelete = () => {
        setShowImage(false)
        setFile("");
    }
    const LoginSchema = Yup.object().shape({});
    const defaultValues = {};
    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods;

    const [value, setValue] = useState();
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchFeature() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminSection/getFeatureById/${id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseJson = await fullResponse.json();
            setValue((prev) => ({
                ...prev,
                feature: responseJson?.data?.feature,
                icon : responseJson?.data?.icon ?? "",
                order : responseJson?.data?.order ?? "",
                isActive: responseJson?.data?.isActive,

            }));
            setShowImage(true);

        }
        fetchFeature();
    }, []);

    


    const onSubmit = async (data) => {
        setLoading(true);
        const formData = new FormData();
        if (file) {
            formData.append("file", file);
        }
        formData.append('id' , id)
        formData.append("feature", value.feature); 
        formData.append('isActive', value.isActive);
        formData.append('order' , value?.order);
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${authToken}`,
            },
        };
        axios
            .put(`${process.env.REACT_APP_LIVE_URL}/adminSection/updateFeature`, formData, config)
            .then((response) => {
                if (response.data.status == true) {
                    reset();
                    setLoading(false);
                    toast.success(response.data.msg);
                    setTimeout(() => {
                        navigate(`/dashboard/batchFeature`);
                    }, 1500);
                } else {
                    toast.error(response.data.msg);
                    setLoading(false);
                }
            })
            .catch((e) => {
                toast.error('Unable to update feature');
                setLoading(false);

            });
    };

    const onChange2 = (e) => {
        const { name, value } = e.target;
        setValue((prev) => ({ ...prev, [name]: value }));
    };
    // console.log(value)
    return (
        <Grid direction="row" pl={5}>
            <Typography variant="h2" m={2}>
                 { `Update  Feature - ${value?.feature?.charAt(0)?.toUpperCase() +  value?.feature?.slice(1)}` } 
            </Typography>


            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3} sx={{ width: "70%" }}>

                        <Stack direction="row">
                            <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Feature : </InputLabel>
                            <RHFTextField name="feature" label={`${value?.feature?.charAt(0)?.toUpperCase() +  value?.feature?.slice(1)}`} type='checkbox' onChange={onChange2} select>
                                <MenuItem value={'lecture'}>Lecture</MenuItem>
                                <MenuItem value={'note'}>Note</MenuItem>
                                <MenuItem value={'dpp'}>Dpp</MenuItem>
                                <MenuItem value={'quiz'}>Quiz</MenuItem>
                                <MenuItem value={'announcement'}>Announcement</MenuItem>
                                <MenuItem value={'doubt'}>Doubt</MenuItem>
                                 <MenuItem value={'community'}>Community</MenuItem>
                                 <MenuItem value={'planner'}>Planner</MenuItem>

                            </RHFTextField>
                        </Stack>
                       
                  
                  <Stack direction="row" spacing={3}>
                     <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}> Icon  : </InputLabel>
                        { showImage ? 
                        <Stack direction='column' sx={{ width: '100%', position: 'relative' }} alignItems="center" spacing={1} >
                        <IconButton sx={{ position: 'absolute', top: '-10px', right: '-10px' }} onClick={() => handleDelete()}>
                            <CloseIcon fontSize='small' />
                        </IconButton>
                        <img src={value?.icon} alt="CategoryIcon" style={{ height: '100px' , width:"200px"}}/>
 
                    </Stack>
                        :
                        <Stack direction='row' sx={{ width : '100%'}} >
                           
                        <RHFTextField 
                            name="file"
                            onChange={changeHandler}
                            id="file"
                            label="Select Image"
                            error={!file && val}
                            helperText='Image Only ( Maximum allowed size 100KB)'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            // inputProps={{ multiple: true }}
                            InputProps={{
                                classes: {
                                    input: "CustomTextField"
                                }
                            }}
                            onClick={() => setPdf(true)}
                            inputProps={{ accept: "image/*" }}
                            type={pdf || focus ? "file" : "text"}

                        />
                        </Stack>
                        }
                       
                  
                    </Stack>
                    <Stack direction="row">
                         <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Order  : </InputLabel>
                         <RHFTextField name="order" value={value?.order} onChange={onChange2}/>
                    </Stack>   
                    <Stack  direction="row">
                        <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Status  : </InputLabel>
                        <RHFTextField
                            name='isActive'
                            label={value?.isActive ? 'Active' : 'Inactive'}
                            sx={{ width: '100%' }}
                            select
                            type="checkbox"
                            onChange={onChange2}
                        >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                    sx={{
                        bgcolor: theme.palette.primary.mainTheme,
                        width: "70%",
                        marginTop: "5%",
                        "&:hover": { background: "red" },
                    }}
                >
                    Update Feature
                </LoadingButton>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Grid>
    );
};

export default EditFeature;