import * as React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import BugReportIcon from '@mui/icons-material/BugReport';
import GroupsIcon from '@mui/icons-material/Groups';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  useGridApiContext,
  gridExpandedSortedRowIdsSelector,
  GridRowEditStopReasons
} from "@mui/x-data-grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import getAccess from "../../../utils/getAccess";



function CustomToolbar({ rows, columns }) {
  const apiRef = useGridApiContext();
  const exportAllRows = async () => {
    const visibleRows = gridExpandedSortedRowIdsSelector(apiRef);
    const filteredRows = rows.filter((row) => visibleRows.includes(row.id));
    const columnIds = columns.filter((column) => column.field != 'actions').map((column) => column.field);
    const csvHeader = columnIds.join(', ');
    const csvContent = filteredRows
      .map((row, index) => {
        const rowValues = columnIds.map((columnId) => {
          if (columnId === 'user') {
            return `${row.user.FullName}(${row.user.Role})`;
          }
          else if (columnId === 'linkWith') {
            return row.linkWith
          }
          else if (columnId === 'serialNumber') {
            return index + 1
          }
          return row[columnId];
        });
        return rowValues.join(', ');
      })
      .join('\n');
    const fullCsvContent = `${csvHeader}\n${csvContent}`;
    const blob = new Blob([fullCsvContent], { type: `text/csv;charset=utf-8` });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'Quizs.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />
      <Tooltip title="Export All">
        <IconButton onClick={exportAllRows}>
          <SaveAltIcon style={{ color: 'red' }} />
        </IconButton>
      </Tooltip>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default function QuizGrid({ data }) {


  const access = getAccess()
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const navigate = useNavigate()
  const handleEditClick = (id) => () => {
    navigate(`/dashboard/updateStoreBanner/${id}`)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    // console.log(id);
    const confirmDelete = window.confirm('Are you sure you want to delete this Quiz?');
    if (confirmDelete) {
      const token = localStorage.getItem('token');
      const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/deleteQuiz/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },

      });
      // setLoadingButton(false)
      const resJson = await res.json();
      if (resJson.status) {
        toast.success(resJson.msg);
        setRows(rows.filter((row) => row.id !== id));
      } else {
        toast.error(resJson.msg);
      }
      // toast.success(resJson);
    };
  }

  const makeActiveAndInActiveQuiz= async (id) => {

    const token = localStorage.getItem("token");
    const response = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/makeActiveAndInActiveQuiz/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },

    });
    const responseJson = await response.json();
    // console.log(responseJson);
    if( responseJson?.status){
      toast.success(responseJson.msg)
      setTimeout(() => window.location.reload()
      , 1000)
    }else{
      toast.error(responseJson.msg);
    }
    // setLoadingButton(false)
    // toast.success('Deleted')
    

  }


  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    // editDataInDb(newRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const rowsWithSerialNumber = rows?.map((row, index) => ({ ...row, serialNumber: index + 1 }));

  const columns = [
    {
      field: 'serialNumber',
      headerName: 'S NO',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <strong>{params.value}</strong>
      ),
    },

    {
      field: "quiz_title",
      headerName: "Title",
      type: "string",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "user",
      headerName: "Created By",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <div>{`${params.value?.FullName} (${params.value?.Role})`}</div>
        )
      }
    },
    {
      field: "quiz_banner",
      headerName: "Image",
      type: "string",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <img
          src={params.value[0]}
          alt={`Not avalible`}
          style={{ width: 150, height: 70 }}
        />
      ),
    },
    {
      field: "quiz_duration",
      headerName: "Quiz Duration(mins)",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "no_ques",
      headerName: "NO. Question",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "eachQueMarks",
      headerName: "Each QueMarks",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "is_negative",
      headerName: "IsNegative",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "negativeMarks",
      headerName: "Negative Marks",
      width: 150,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "link",
      headerName: "Link",
      type: "number",
      width: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "linkWith",
      headerName: "Link With",
      type: "object",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value.title ? params.value.title : "NA"}</div>
        // <div>{params.value}</div>
      ),
    },
    {
      field: "language",
      headerName: "Language",
      width: 110,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div>{params.value == 'en' ? "English" :  params.value == 'hi' ?  "Hindi"  :  "Hindi/English"}</div>
        // <div>{params.value}</div>
      ),

    },

    {
      field: "is_active",
      headerName: "Status",
      type: "boolean",
      width: 100,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <div style={{ color: params.value == true ? 'green' : 'red' }}>
          {params.value ? 'Active' : 'inActive'}
        </div>
      ),

    },
    // {
    //   field: "createdAt",
    //   headerName: "Created At",
    //   type: "object",
    //   width: 150,
    //   align: "left",
    //   headerAlign: "left",

    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 250,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rowData = rows.find((item) => item.id == id);

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
            <Tooltip title="Edit">
              <GridActionsCellItem
                icon={<VisibilityIcon />}
                label="Edit"
                className="textPrimary"
                onClick={() => navigate(`/dashboard/quiz/${rowData.quiz_title}/${id}`)}
                color="inherit"
              />
            </Tooltip>
            : <></>),
          ((access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
            <Tooltip title="Edit">
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={() => navigate(`/dashboard/updateQuiz/${id}`)}
                color="inherit"
              />
            </Tooltip>
            : <></>),
          ((access?.deleteAccess || access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
            <Tooltip title={`${rowData?.is_active == true ? 'Make inActive' : 'Make Active'}`}>
              <GridActionsCellItem
                icon={ rowData?.is_active ? <ToggleOffIcon style={{ color:'green'}}  /> : <ToggleOnIcon style={{color : 'red'}}/>}
                label="Change Status"
                onClick={() => makeActiveAndInActiveQuiz(id)}
                color="inherit"
              />
            </Tooltip> : <></>),
             ((access?.deleteAccess || access?.access.includes('all')) ?
             <Tooltip title="Delete">
               <GridActionsCellItem
                 icon={<DeleteIcon style={{ color: 'red' }} />}
                 label="Delete"
                 onClick={handleDeleteClick(id)}
                 color="inherit"
               />
             </Tooltip> : <></>),
          ((access?.accessForTab == 'read' || access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
            <Tooltip title="Doubts">
              <GridActionsCellItem
                icon={<PsychologyAltIcon style={{ color: 'blue' }} />}
                label="Edit"
                className="textPrimary"
                onClick={() => navigate(`/dashboard/quiz/doubts/${rowData.quiz_title}/${id}`)}
                color="inherit"
              />
            </Tooltip> : <></>),
          ((access?.accessForTab == 'read' || access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
            <Tooltip title="Issues">
              <GridActionsCellItem
                icon={<BugReportIcon />}
                label="Edit"
                className="textPrimary"
                onClick={() => navigate(`/dashboard/quiz/issues/${rowData.quiz_title}/${id}`)}
                color="inherit"
              />
            </Tooltip> : <></>),
          ((access?.accessForTab == 'read' || access?.accessForTab == 'readWrite' || access?.access.includes('all')) ?
            <Tooltip title="Quiz Students">
              <GridActionsCellItem
                icon={<GroupsIcon />}
                label="Edit"
                className="textPrimary"
                onClick={() => navigate(`/dashboard/quiz/quizStudents/${rowData.quiz_title}/${id}`)}
                color="inherit"
              />
            </Tooltip> : <></>),
        ];
      }
    }
  ]



  return (
    <Box
      sx={{
        height: 590,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <DataGrid rowHeight={50}

        rows={rowsWithSerialNumber}
        columns={columns}
        getRowHeight={() => 'auto'}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 7,
            },
          },
        }}
        pageSizeOptions={[7, 25, 50]}
        slots={{
          toolbar: (gridProps) => <CustomToolbar {...gridProps} rows={rows} columns={columns} />,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true, }
        }}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
